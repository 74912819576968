<template>
<div>
	<b-jumbotron>
		<b-form @submit.prevent="recuperoInformazioniLogs">
			<b-form-row>
				<b-col>
					<b-form-group
						label="Descrizione evento"
						label-for="input-descrizione-evento-filter"
						label-size="md">
						<b-form-input
							id="input-descrizione-evento-filter"
							v-model="inputDescrizioneEvento"
							type="search"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group
						label="Indirizzo IP"
						label-for="input-ip-filter"
						label-size="md">
						<b-form-input
							id="input-ip-filter"
							v-model="inputIp"
							type="search"></b-form-input>
					</b-form-group>
				</b-col>
			</b-form-row>
			<b-form-row>
				<b-col>
					<b-form-group
						label="Id Risorsa"
						label-for="select-risorsa-filter"
						label-size="md">
						<b-form-select
							id="select-risorsa-filter"
							:options="optionsIdRisorsa"
							v-model="selectIdRisorsa"></b-form-select>
					</b-form-group>
				</b-col>
			</b-form-row>
			<b-form-row>
				<b-col>
					<b-form-group
						label="Operation"
						label-for="select-operation-filter"
						label-size="md">
						<b-form-select
							id="select-operation-filter"
							:options="operationOptions"
							v-model="selectOperation"></b-form-select>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group
						label="Client Name"
						label-for="select-client-name-filter"
						label-size="md">
						<b-form-select
							id="select-client-name-filter"
							:options="optionsClientName"
							v-model="selectClientName"
							type="search"></b-form-select>
					</b-form-group>
				</b-col>
			</b-form-row>
			<b-form-row>
				<b-col>
					<b-form-group
						label="Data Ora"
						label-for="date-picker-dataOra"
						label-size="md">
						<date-picker
							id="date-picker-dataOra"
							v-model="datePickerDataOra"
							type="datetime"
							value-type="timestamp"
							format="DD/MM/YYYY HH:mm:ss"
							:confirm="true"
							:clearable="true"
							:editable="false"
							:range="true"
							class="date-picker"></date-picker>
					</b-form-group>
				</b-col>
			</b-form-row>
			<b-form-row>
				<b-col class="text-right">
					<b-button
						type="submit"
						variant="primary">Cerca</b-button>
				</b-col>
			</b-form-row>
		</b-form>
		<b-row class="mt-2">
			<b-col>
				<b-table
					id="table-logs"
					striped
					hover
					responsive
					:current-page="currentPage"
					:per-page="0"
					:busy="tableIsBusy"
					:fields="fieldsTable"
					:items="logs">
					<template #table-busy>
						<div class="text-center text-dark my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong> Caricamento in corso</strong>
						</div>
					</template>
					<template #cell(dataOra)="row">
						<label>{{DateTime.fromMillis(row.item.dataOra).toFormat('dd/MM/yyyy HH:mm:ss')}}</label>
					</template>
				</b-table>
				<b-pagination
					@input="onInputPage"
					align="center"
					v-model="currentPage"
					:total-rows="totalRows"
					:per-page="perPage"
					aria-controls="table-logs"></b-pagination>
			</b-col>
		</b-row>
	</b-jumbotron>
</div>
</template>

<script>
import DashboardServices from '../services/DashboardServices';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/it';
import {DateTime} from 'luxon';

export default {
	name: "Logs",
	computed: {
		DateTime() {
			return DateTime
		}
	},
	components: {
		DatePicker
	},
	data() {
		return {
			logs: [],
			tableIsBusy: false,
			jsonDataFiltri: {},
			inputDescrizioneEvento: '',
			inputIp: '',
			optionsIdRisorsa: [
				{ value: null, text: 'Seleziona la risorsa' }
			],
			selectIdRisorsa: null,
			datePickerDataOra: [null, null],
			totalRows: 0,
			currentPage: 1,
			perPage: 10,
			selectOperation: null,
			operationOptions: [
				{ value: null, text: 'Seleziona l\'operazione' },
				{ value: 'DELETE', text: 'DELETE' },
				{ value: 'GET', text: 'GET' },
				{ value: 'POST', text: 'POST' },
				{ value: 'PUT', text: 'PUT' }
			],
			selectClientName: null,
			optionsClientName: [
				{ value: null, text: 'Seleziona il client' }
			],
			fieldsTable: [
				{ key: 'descrizioneEvento' },
				{ key: 'dataOra', sortable: true },
				{ key: 'ip' },
				{ key: 'idRisorsa' },
				{ key: 'operation' },
				{ key: 'sezione' },
				{ key: 'stato' },
				{ key: 'userAgent' },
				{ key: 'clientName' }
			]
		}
	},
	mounted() {
		this.recuperoListaIdRisorse();
		this.recuperoListaClient();
		this.recuperoInformazioniLogs();
	},
	methods: {
		recuperoInformazioniLogs() {
			this.tableIsBusy = true;
			this.jsonDataFiltri.descrizioneEvento = this.inputDescrizioneEvento === '' ? undefined : this.inputDescrizioneEvento;
			this.jsonDataFiltri.dataOraInizioRicerca = this.datePickerDataOra[0] == null ? undefined : this.datePickerDataOra[0];
			this.jsonDataFiltri.dataOraFineRicerca = this.datePickerDataOra[1] == null ? undefined : this.datePickerDataOra[1];
			this.jsonDataFiltri.ip = this.inputIp === '' ? undefined : this.inputIp;
			this.jsonDataFiltri.idRisorsa = this.selectIdRisorsa ?? undefined;
			this.jsonDataFiltri.operation = this.selectOperation ?? undefined;
			this.jsonDataFiltri.clientName = this.selectClientName ?? undefined;
			DashboardServices.getLogs(this.$router, (this.currentPage - 1) * this.perPage, this.perPage, this.jsonDataFiltri)
				.then(response => {
					if (response.error) {
						this.$showMsgBoxDanger('Si è verificato un errore', response.msg, false);
						return;
					}
					this.totalRows = response.data.totalRows;
					this.logs = response.data.logs;
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					this.tableIsBusy = false;
				});
		},
		recuperoListaIdRisorse() {
			let me = this;
			DashboardServices.getServiziAnagrafiche(this.$router)
				.then(response => {
					if (response.error) {
						this.$showMsgBoxDanger('Si è verificato un errore', response.msg, false);
						return;
					}
					response.data.forEach(item => {
						me.optionsIdRisorsa.push({
							value: item.codiceNazionaleStruttura,
							text: item.codiceNazionaleStruttura
						});
					});
				})
				.catch(error => {
					console.log(error);
				});
		},
		recuperoListaClient() {
			let me = this;
			DashboardServices.getClientsName(this.$router)
				.then(response => {
					if (response.error) {
						this.$showMsgBoxDanger('Si è verificato un errore', response.msg, false);
						return;
					}
					response.data.forEach(item => {
						me.optionsClientName.push({
							value: item.name,
							text: item.name
						});
					});
				})
				.catch(error => {
					console.log(error);
				});
		},
		onInputPage() {
			this.recuperoInformazioniLogs();
		}
	}
}
</script>

<style scoped>
.date-picker {
	width: 100%;
}
</style>
